<template>
  <div>
      <div v-if="$system_variables.status_task_loaded==1">
        <div class="card d-print-none mb-2">
          <div class="card-body">
              <a-button type="primary" :class="'mr-2 mb-2'" @click="addItem">{{$system_functions.get_label('button_new')}}</a-button>
              <a-button type="primary" :class="'mr-2 mb-2'" @click="saveDb">Save Database</a-button>
              <a-button type="primary" :class="'mr-2 mb-2'" @click="clickUpload"> <a-icon type="upload" /> Select File </a-button>
              <input type="file" @change="loadDb" id="fileLoadDb" style="display:none" >
              <a-button type="primary" :class="'mr-2 mb-2'" @click="exportCsv">Save as Csv</a-button>
              <a-button type="primary" :class="'mr-2 mb-2'" onclick="window.print();">{{$system_functions.get_label('button_print')}}</a-button>
              
          </div>
        </div>
        <a-card>
          <a-table :columns="columns" :data-source="items" :pagination="false" :class="'hasactionColumnLast'">
            <template slot="actions" slot-scope="text, record">
              <a-icon type="edit" theme="filled" :style="{ cursor: 'pointer', color: '#00ff00' }" @click="editItem(record.id)"/>
              <a-popconfirm
                title="Sure to delete?"
                @confirm="() => deleteItem(record.id)"
              >
                <a-icon type="delete" theme="filled" :style="{ cursor: 'pointer', color: '#ff0000' }" />              
              </a-popconfirm>              
          </template>
          </a-table>
        </a-card>
        <a-modal v-model="showAddEdit" title="Basic Modal" @ok="saveItem" :maskClosable="false" :okText="'Save'">
          <a-form-model ref="ruleForm" :model="item" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
            <a-form-model-item has-feedback label="Activity" prop="activity">
              <a-input v-model="item.activity" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item has-feedback label="Location" prop="location">
              <a-input v-model="item.location" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item label="Activity time" prop="date">
              <a-date-picker
                v-model="item.date"             
                type="date"
                show-time
                placeholder="Pick a date"
                style="width: 100%;"
                @change="onChangeDate"
              />
             </a-form-model-item>
          </a-form-model>
        </a-modal>        
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import fs from 'fs';
export default {
  name: 'Home',
  props: {
    msg: String
  },
  mounted:function()
  {
      this.$system_variables.status_task_loaded=1;  
      this.$system_variables.status_data_loaded=1;
      this.init();
         
           
  },
  data() {
    return {
      columns: [{title: 'Activity',dataIndex: 'activity'},
                {title: 'Location',dataIndex: 'location'},
                {title: 'Date',dataIndex: 'date'},
                {title: 'Actions',dataIndex: 'actions',scopedSlots: { customRender: 'actions' }}
              ],
      items:[],
      item:{'id':0,'activity':'','location':'','date':null,'dateString':''},
      reloadItems:false,
      showAddEdit:false,
      ruleForm: {
        pass: '',
        checkPass: '',
        age: '',
      },
      rules: {
        activity: [{ required: true, message: 'Please input Activity name', trigger: 'blur' },
          { min: 3,message: 'Activity name should be minimum 3', trigger: 'blur' },],
        location: [{ required: true, message: 'Please input a Location name', trigger: 'blur' },
          { min: 3,message: 'Location Name should be minimum 3', trigger: 'blur' },],
        date: [{ required: true, message: 'Please pick a date', trigger: 'change' }],
       
      }
      
    }
  },
  methods: { 
    beforeUpload(file) {
      console.log(file);
      return false;
    },
    init:async function()
    {
      try{
        const initSqlJs = require('sql.js');
        // const SQL = await initSqlJs({
        //   // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
        //   // You can omit locateFile completely when running in node
        //   locateFile: file => `https://sql.js.org/dist/${file}`
        // });
        // this.$system_variables.database = new SQL.Database();
        // this.initTabels();
      }
      catch(error) {
          console.log(error);

        }
    },
    initTabels: function()
    {
      var todoTable='CREATE TABLE IF NOT EXISTS todo ('
                    +'id INTEGER PRIMARY KEY,'
                    +'activity TEXT NOT NULL,'
                    +'location TEXT,'
                    +'date TEXT);';
      if(this.$system_variables.database)
      {
        this.$system_variables.database.run(todoTable);
        //this.$system_variables.database.run("INSERT INTO todo (activity,location,date) VALUES (?,?,?)", ['Shaiful',"Dhaka",'2020-10-12']);
        //this.$system_variables.database.run("INSERT INTO todo (activity,location,date) VALUES (?,?,?)", ['islam',"Dhaka",'2020-10-12']);
        //this.$system_variables.database.run("INSERT INTO todo (activity,location,date) VALUES (?,?,?)", ['sunan',"Dhaka",'2020-10-12']);

      }
                    

                    
    },
    getItems:function()
    {
        if(this.reloadItems)
        {
          this.items=[];
          var stmt = this.$system_variables.database.exec("SELECT * FROM todo"); 
          if(stmt.length>0)
          {
            var values=stmt[0].values;
            for(var i=0;i<values.length;i++)
            {
              this.items[i]={'id':values[i][0],'activity':values[i][1],'location':values[i][2],'date':values[i][3]};
            }
            this.reloadItems=false;
            //console.log(this.items);
            
          }
                    
        }
    },
    onChangeDate:function(value, dateString) {
     this.item.dateString=dateString;
    },
    addItem:function()
    {
      this.item={'id':0,'activity':'','location':'','date':null,'dateString':''};
      //this.item={'activity':'','location':'','date':moment('2015-01-01', 'YYYY-MM-DD')};
      this.showAddEdit=true;
    },
    editItem:function(id)
    {
      var stmt = this.$system_variables.database.exec("SELECT * FROM todo where id=?",[id]); 
          if(stmt.length>0)
          {
            var value=stmt[0].values[0];
            this.item={'id':value[0],'activity':value[1],'location':value[2],'date':moment(value[3]),'dateString':value[3]};
            //this.item={'id':'''activity':'','location':'','date':moment('2015-01-01', 'YYYY-MM-DD')};
            //console.log(value);
            
          }
      this.showAddEdit=true;
    },
    saveItem:function()
    {
      this.$refs.ruleForm.validate(valid => {
        if (valid) 
        {
          console.log(this.item.date);
          if(this.item.id>0)
          {
            this.$system_variables.database.run("UPDATE todo SET activity= ?,location = ?,date= ? Where id= ?", [this.item.activity,this.item.location,this.item.dateString,this.item.id]);
          }
          else
          {
            this.$system_variables.database.run("INSERT INTO todo (activity,location,date) VALUES (?,?,?)", [this.item.activity,this.item.location,this.item.dateString]);
          }
          this.showAddEdit=false;
          this.reloadItems=true;          
          this.getItems();
        } 
        else 
        {
          console.log('error submit!!');
          return false;
        }
      });
      //console.log(this.item);
      //this.item={'activity':'','location':''};
      //console.log('save item');
    },
    deleteItem:function(id)
    {
      var stmt = this.$system_variables.database.exec("Delete FROM todo where id=?",[id]); 
      this.reloadItems=true;
      this.getItems();
    },
    saveDb:function(event)
    {
      var fs = require("fs");
      // [...] (create the database)
      var data = this.$system_variables.database.export();
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(new Blob([data]));
      a.download = "database.sqlite";
      a.onclick = function () {
        setTimeout(function () {
          window.URL.revokeObjectURL(a.href);
        }, 1500);
      };
      a.click();
    },
    clickUpload:function()
    {
      document.getElementById('fileLoadDb').click();
    } ,  
    loadDb:async function()
    {
      this.$system_variables.status_data_loaded=0;  
      var $root=this;
      try{
          
          const initSqlJs = require('sql.js');
            const SQL = await initSqlJs({
              // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
              // You can omit locateFile completely when running in node
              locateFile: file => `https://sql.js.org/dist/${file}`
            });
            var f=document.getElementById('fileLoadDb').files[0];
          var r = new FileReader();
          r.onload = function() {
            try{
            var Uints = new Uint8Array(r.result);
            $root.$system_variables.database = new SQL.Database(Uints);
            
             
            $root.initTabels();
            $root.$system_variables.status_data_loaded=1;
            $root.reloadItems=true;          
            $root.getItems();
            }
            catch(error2)
            {
              $root.$system_variables.status_data_loaded=1;
              alert("loading Error");
            }
          }
          r.readAsArrayBuffer(f);
      }
      catch(error)
      {
        this.$system_variables.status_data_loaded=1;
        alert("loading Error");
      }
      
    },
    exportCsv: function()
    {
        var csvStr='"id","activity","location","date"';//headers
        
        csvStr+="\n";
        /*for (var j=0;j<datas.length;j++)
        {
            for(var i=0;i<headers.length;i++)
            {
                if(headers[i].fieldType=='date')
                {
                    csvStr=csvStr+'"'+this.displayDate(datas[j][headers[i].key])+'",';
                }
                else
                {
                    csvStr=csvStr+'"'+datas[j][headers[i].key]+'",';
                }  
            }
            csvStr+="\n";
        }*/
        if(this.items.length>0)
        {
          for(var i=0;i<this.items.length;i++)
          {
            //console.log(this.items[i]);
            csvStr=csvStr+'"'+this.items[i].id+'",'+'"'+this.items[i].activity+'",'+'"'+this.items[i].location+'",'+'"'+this.items[i].date+'",';            
            csvStr+="\n";
          }
        }
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvStr);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'output.csv';
        hiddenElement.click();            
    }
  },
}
</script>
